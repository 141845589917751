
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_comments(params)  {
            return await axios.post('comments/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_comments()  {
            // return await axios.get('comments/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_comments')
        },
        async delete_comments(comment_id)  {
            return await axios.post('comments/delete.php' , {
                comment_id : comment_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(comment_id)  {
            return await axios.post('comments/readOne.php' , {
                comment_id : comment_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_comments(params)  {
            return await axios.post('comments/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_comments(params)  {
            return await axios.post('comments/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
