
    <template>
    <div>
    <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else>
            <form @submit.prevent="add_comments" autocomplete="off">
                <v-layout row wrap>
        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="comments.comment_text" type="text" :label="$store.getters.lang.data.comment_text" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="comments.comment_date" type="number" :label="$store.getters.lang.data.comment_date" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="comments.comment_image" type="text" :label="$store.getters.lang.data.comment_image" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


            <v-flex xs12 lg2 xl2 md3 sm4>
            <v-select class="mx-1" clearable rounded filled :items="users" v-model="comments.user_id" outlined  item-text="user_id"
                            item-value="user_id" :return-object="false" :label="$store.getters.lang.data.user_id">
                        </v-select>
                        </v-flex>
            
            <v-flex xs12 lg2 xl2 md3 sm4>
            <v-select class="mx-1" clearable rounded filled :items="items" v-model="comments.item_id" outlined  item-text="item_id"
                            item-value="item_id" :return-object="false" :label="$store.getters.lang.data.item_id">
                        </v-select>
                        </v-flex>
            
        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="comments.comment_like_count" type="number" :label="$store.getters.lang.data.comment_like_count" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="comments.comment_report_count" type="number" :label="$store.getters.lang.data.comment_report_count" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="comments.comment_status" type="text" :label="$store.getters.lang.data.comment_status" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn rounded color="primary" type="submit" x-large >{{$store.getters.lang.data.add_btn}} comments</v-btn>
                    </v-flex>
                </v-layout>
            </form>
            <v-layout row wrap mt-5>
                <v-flex xs12>
                    <v-card>
                        <v-card-title primary-title>
                        {{$store.getters.lang.data.comments}}
                            <v-spacer></v-spacer>
                            <v-text-field autocomplete="off" v-model="search" append-icon="fas fa-search">
                            </v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="headers" :search="search" :items="rows" class="elevation-0"
                                item-key="comment_id">
                                <template v-slot:[`item.comment_id`]="{ item }">
                                    <div>
                                        <v-btn icon color="error" :disabled="$store.state.auth.user.admin_role != 'admin'" class="mx-1" @click="select_comments(item)" rounded>
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>
                                        <v-btn :to="'/comments-list/'+item.comment_id" icon color="secondary" class="mx-1"
                                            rounded>
                                            <v-icon>mdi-pencil-outline</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-dialog v-model="delete_dialog" persistent max-width="290">
            <v-card>
                <v-card-title>
                    {{$store.getters.lang.data.delete_question}}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="elevation-0" @click="delete_dialog = false">
                    {{$store.getters.lang.data.cancel_btn}}
                    </v-btn>
                    <v-btn color="green darken-1" text @click="delete_comments(selected_comments.comment_id)">
                    {{$store.getters.lang.data.yes_btn}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import controller from './../../controller/comments'
    export default {
        data() {
            return {
                comments: {},
                search: '',
                loading : true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                selected_comments: {},
                delete_dialog: false,
                headers: [
                    
        {
            text: this.$store.getters.lang.data.comment_id,
            align: 'start',
            sortable: true,
            value: 'comment_id',
        },


        {
            text: this.$store.getters.lang.data.comment_text,
            align: 'start',
            sortable: true,
            value: 'comment_text',
        },


        {
            text: this.$store.getters.lang.data.comment_date,
            align: 'start',
            sortable: true,
            value: 'comment_date',
        },


        {
            text: this.$store.getters.lang.data.comment_image,
            align: 'start',
            sortable: true,
            value: 'comment_image',
        },


        {
            text: this.$store.getters.lang.data.user_id,
            align: 'start',
            sortable: true,
            value: 'user_id',
        },


        {
            text: this.$store.getters.lang.data.item_id,
            align: 'start',
            sortable: true,
            value: 'item_id',
        },


        {
            text: this.$store.getters.lang.data.comment_like_count,
            align: 'start',
            sortable: true,
            value: 'comment_like_count',
        },


        {
            text: this.$store.getters.lang.data.comment_report_count,
            align: 'start',
            sortable: true,
            value: 'comment_report_count',
        },


        {
            text: this.$store.getters.lang.data.comment_status,
            align: 'start',
            sortable: true,
            value: 'comment_status',
        },


                ],
            }
        },
        computed: {
            rows(){
                return this.$store.getters.comments
            },
            
                    users (){
                        return this.$store.getters.users
                    },
                    
                    items (){
                        return this.$store.getters.items
                    },
                    
            
        },
        mounted(){
            this.read_comments();
        },
        methods: {
            add_comments() {
                controller.add_comments(this.comments).then(r => {
                    if (r.data) {
                        this.comments = {}
                        this.$store.state.comments.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'comments Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add comments',
                            color: 'error'
                        }
                    }
                })
            },
            delete_comments(comment_id) {
                controller.delete_comments(comment_id).then(r => {
                    this.delete_dialog = false
                    if (r.data) {
                        this.$store.state.comments = this.$store.state.comments.filter(f =>{
                            return f.comment_id != comment_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'comments Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            read_comments() {
                this.loading = true
                controller.get_comments().then(r => {
                    // this.rows = r.data.rows
                    this.loading = false
                })
            },
            select_comments(i) {
                this.selected_comments = i
                this.delete_dialog = true
            },
        },
    }
</script>
